<script setup lang="ts" generic="T extends any">
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Pagination } from 'swiper/modules';
    import 'swiper/css';
    import 'swiper/css/pagination';

    type PaginatedSliderProps = {
        slideData: T[];
    };

    defineProps<PaginatedSliderProps>();

    const { setMainSwiper, setControls, onSlideFocus } = useGallery();

    const md = screens('md');
    const lg = screens('lg');
</script>
<template>
    <div class="org-paginated-slider">
        <nuxt-error-boundary @error="() => {}">
            <swiper
                :slides-per-view="1"
                :lazy="true"
                :breakpoints="{
                    [md]: {
                        slidesPerView: 2,
                    },
                    [lg]: {
                        slidesPerView: 4,
                    },
                }"
                :pagination="{
                    clickable: true,
                    dynamicBullets: false,
                }"
                :modules="[Pagination]"
                :space-between="16"
                @slide-change="setControls"
                @swiper="setMainSwiper"
                auto-height
                loop>
                <swiper-slide
                    v-for="(data, index) in slideData"
                    class="w-full md:w-1/2 lg:w-1/4"
                    :key="index"
                    @focusin="() => onSlideFocus(index)">
                    <slot
                        :data="data"
                        :index="index"></slot>
                </swiper-slide>
            </swiper>
        </nuxt-error-boundary>
    </div>
</template>

<style scoped>
    :deep(.swiper-pagination-bullet-active) {
        @apply bg-woom-black;
    }
    :deep(.swiper-pagination) {
        @apply relative mt-5 lg:hidden;
    }
    :deep(.swiper-horizontal > .swiper-pagination-bullets),
    :deep(.swiper-pagination-bullets.swiper-pagination-horizontal) {
        @apply flex justify-center lg:hidden;
    }
    :deep(.swiper-pagination-bullet) {
        @apply mx-0 my-2;
    }
</style>
